.q-table__card,
.q-item__label--caption,
.q-field__control,
.q-field__native,
.q-field__prefix,
.q-field__suffix,
.q-field__input,
.q-field__bottom,
.q-item__label--header,
.q-field__marginal {
  color: inherit !important;
}

.q-item__label--header {
  font-weight: 700;
}

.q-img__image {
  opacity: 1;
}

.q-btn,
.q-btn--dense {
  padding-top: 0;
  padding-bottom: 0;
}

.q-loading > div {
  margin: 0;
  max-width: none;
}
